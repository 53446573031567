import { useEffect, useState } from "react";
import "./App.css";
import { ZoomMtg } from "@zoomus/websdk";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.7.0/lib", "/av");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

function Launcher() {
  //wrapper component so we can use effect

  const queryString = window.location.search;
  //querystring pattern /?meetingNumber=123456&role=0&userName=Nasir&userEmail=nasir@mail.com&passWord=4fgas
  const urlParams = new URLSearchParams(queryString);

  var meetingNumber = urlParams.get("meetingNumber");
  var role = urlParams.get("role");
  const [userName, setUserName] = useState(urlParams.get("userName"));
  const [userEmail, setUserEmail] = useState(urlParams.get("userEmail"));
  var passWord = urlParams.get("passWord");
  const [meetingStarts, setMeetingStarts] = useState(false);

  const signatureEndpoint = process.env.REACT_APP_ZOOM_SIGNER_URL;
  const sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY;
  var leaveUrl = process.env.REACT_APP_MAIN_URL || "http://google.com";

  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  var registrantToken = "";

  useEffect(() => {
    getSignature();
  }, [meetingNumber, role, passWord]);

  if (!meetingNumber || !role || !passWord) {
    // alert("invalid meeting credentials");
    return window.location.replace(leaveUrl);
  }

  function getSignature() {
    if (userName && userEmail) {
    fetch(signatureEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        startMeeting(response.signature);
      })
      .catch((error) => {
        // alert("Meeting could not be started");
        console.error(error);
      });
    }
  }

  function startMeeting(signature) {
    setMeetingStarts(true);
    document.getElementById("zmmtg-root").style.display = "block";
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success);
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          sdkKey: sdkKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            setMeetingStarts(false);
            console.log(error);
          },
        });
      },
      error: (error) => {
        setMeetingStarts(false);
        console.log(error);
      },
    });
  }

  return (
    <div className="App">
      <main>
        <p
        hidden={meetingStarts ? false : true}
          style={{
            backgroundColor: "gold",
            padding: "8px 16px",
            position: "absolute",
            top: 0,
            fontWeight: 600,
            borderRadius: "4px",
            boxShadow: "0.3px 1.3px 4px 0.5px grey",
          }}
        >
          Loading...
        </p>
        <h1 
        hidden={meetingStarts ? false : true}
        >AWC LIVE STREAM</h1>
        {/* add a form for user to enter their name and email and press Proceed */}
        <form
        hidden={meetingStarts ? true : false}
          onSubmit={(e) => {
            e.preventDefault();
            getSignature();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "400px",
            margin: "auto",
            marginTop: "100px",
          }}
        >
          <label htmlFor="userName">Name</label>
          <input
            type="text"
            id="userName"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
          <label htmlFor="userEmail">Email</label>
          <input
            type="email"
            id="userEmail"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
          />
          <button type="submit">Proceed</button>
        </form>
      </main>
    </div>
  );
}

function App() {
  return <Launcher />;
}

export default App;
